/*.slick-track {
	padding-top: 40px;
	padding-bottom: 40px;
}

.slick-slide {
  opacity: 0.3;
  transition: all .5s;
  height: 100%;
}

.slick-active {
	transform: scale(1.2);
	opacity: 1;
}

.slick-active div {
  height: 100% !important;
}

.slick-active img {
  border: 2px solid white;
}

@media only screen and (max-width: 800px) {
  .slick-center {
    transform: scale(1);
    opacity: 1;
  }

  .slick-slide {
    opacity: 1;
    transition: all .5s;
    height: 100%;
    width: 100vw !important;
  }

  .slick-center img {
    border: 0 !important;
  }
}*/

.newstitle::after {
  content: "";
  border-right: 20px solid #005580;
  border-top: 35px solid transparent;
  display: block;
  height: 0px;
  left: -27px;
  position: absolute;
  top: 0;
  width: 7px;
  border-right-color: #1b3075;
}

.newstitle::before {
  content: "";
  border-left: 20px solid #1b3075;
  border-top: 35px solid transparent;
  display: block;
  height: 0px;
  right: -27px;
  position: absolute;
  top: 0;
  width: 7px;
  border-right-color: #1b3075
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  position: fixed;
  z-index: 1200;
  width: 200px;
  right: 0;
  top: 0;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.flex-center-rt {
  display: flex;
  justify-content: center;
  align-items: center;
}